import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { DatasearchService } from '../_services/datasearch.service';
import { map, catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IpCheckGuard implements CanActivate {
  private allowedIps: string[] = [];

  constructor(
    private router: Router,
    private dataSearch: DatasearchService,
    private http: HttpClient
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.getCurrentIp().pipe(
      switchMap((ip: string) =>
        this.dataSearch.isIpWhiteListed({ ip: ip }).pipe(
          map((response: any) => {
            if (response?.isIpWhiteListed) {
              return true;
            } else {
              alert("You don't have permission to view this page");
              this.router.navigate(['/']);
              return false;
            }
          }),
          catchError(() => {
            alert('An error occurred while checking your IP.');
            this.router.navigate(['/']);
            return of(false);
          })
        )
      ),
      catchError(() => {
        alert('Unable to fetch your IP address');
        this.router.navigate(['/']);
        return of(false);
      })
    );
  }

  private getCurrentIp(): Observable<string> {
    return this.http.get<any>('https://backend.chemrobotics.com/get-user-ip').pipe(
      map((res) => res.ip),
      catchError(() => {
        alert('Unable to fetch your IP address');
        return of('');
      })
    );
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-platform-urls',
  templateUrl: './platform-urls.component.html',
  styleUrls: ['./platform-urls.component.scss']
})
export class PlatformUrlsComponent implements OnInit {
  pageTitle: string = '';
  currentMenuDtls: any;
  cols: any = [
    { field: 'Platform', header: 'Platform', type: 'text' },
    { field: 'Url', header: 'URL', type: 'text' }    
  ];
  platforms: any = [
    {Platform: 'QuikPatent Admin', Url: 'https://mongoadmin.chemrobotics.com/#/quik-patent'},
    {Platform: 'ChemiTracker Admin', Url: 'https://mongoadmin.chemrobotics.com/#/chemi-tracker'},
    {Platform: 'QuikPatent', Url: 'https://quikpatent.chemrobotics.com/'},
    {Platform: 'ChemiTracker', Url: 'https://www.chemitracker.chemrobotics.com/'},
    {Platform: 'Agropharm-XIM 1.0 (New Server)', Url: 'https://agropharmexim.chemrobotics.com/#/'},
    // {Platform: 'Agropharm-XIM 2.0', Url: 'https://agropharmexim.chemrobotics.com/#/agropharm-xim-new-beta'},
    {Platform: 'Agropharm-XIM 2.0 (New Server)', Url: 'https://agropharmeximnew.chemrobotics.com/'},
    {Platform: 'beta QuikPatent (New Server)', Url: 'https://betaquikpatent.chemrobotics.com/'},
    {Platform: 'ActivePatents (New Server)', Url: 'https://activepatents.chemrobotics.com/'},
    {Platform: 'IPDnew (New Server)', Url: 'https://ipdnew.chemrobotics.com/'},
    {Platform: 'Agropat Discovery (New Server)', Url: 'https://agropatdiscovery.chemrobotics.com/'},
    {Platform: 'QuikPatent and Chemrobotics Admin (New Server)', Url: 'https://newmongoadmin.chemrobotics.com/#/'},
    {Platform: 'Idea2Reality Admin (New Server)', Url: 'https://admin.chemrobotics.com/login'},
  ];

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.currentMenuDtls = JSON.parse(
        sessionStorage.getItem('currentMenuDtls')
      );
      this.pageTitle = this.currentMenuDtls.menu_title;
    }, 1000);    
  }

}
